<template>
	<div class="register-wrap">
		<Spin fix v-if="loading"></Spin>
		<section class="register-logo">
			<img src="../../assets/common/logo.png" />
		</section>
		<section class="register-body">
			<div class="register-main">
				<div class="register-main-title">新用户注册</div>
				<Form
					ref="formItem"
					:model="formData"
					:rules="ruleValidate"
					class="register-form"
					label-position="top"
				>
					<div class="title">所属组织</div>
					<Row :gutter="60">
						<Col span="12">
							<FormItem prop="orgType" label="类型">
								<Select
									v-model="formData.orgType"
									placeholder="请选择组织类型"
									@on-change="orgTypeChange"
								>
									<Option
										:value="list.id"
										v-for="list in dicList"
										:key="list.id"
										:label="list.name"
									></Option>
								</Select>
							</FormItem>
						</Col>
						<Col span="12">
							<FormItem prop="orgName" label="全称">
								<Input
									:maxlength="128"
									v-model.trim="formData.orgName"
									placeholder="请输入全称"
								/>
							</FormItem>
						</Col>
						<Col span="24">
							<FormItem
								prop="businessLicence"
								:required="true"
								:show-message="false"
								label="营业执照/企业资质"
							>
								<Row :gutter="60">
									<Col
										:span="12"
										class="img-preview-wrap"
										v-for="(item, index) in formData.businessLicence"
										:key="index"
									>
										<img :src="getPreviewImg(item)" class="preview" />
										<Icon
											type="md-close"
											@click="delBusinessLicenceImg(index)"
										/>
									</Col>
									<Col :span="12">
										<Upload
											style="display: inline-block; width: 100%"
											action="/"
											:accept="filetype"
											:show-upload-list="false"
											:before-upload="file => getMD5(file, 'businessLicence')"
										>
											<div
												class="upload-btn-wrap"
												v-if="
													!formData.businessLicence ||
													formData.businessLicence.length < 6
												"
											>
												<Button
													type="primary"
													shape="circle"
													icon="md-add"
												></Button>
												<p>上传营业执照</p>
											</div>
										</Upload>
									</Col>
								</Row>
							</FormItem>
						</Col>
					</Row>
					<div class="title">所属部门</div>
					<Row :gutter="60">
						<Col span="12">
							<FormItem prop="deptName" label="名称">
								<Input
									v-model.trim="formData.deptName"
									:maxlength="64"
									placeholder="请输入名称"
								/>
							</FormItem>
						</Col>
					</Row>
					<div class="title">用户信息</div>
					<Row :gutter="60">
						<Col span="12">
							<FormItem prop="username" label="邮箱地址">
								<Input
									v-model.trim="formData.username"
									placeholder="请输入邮箱地址"
								/>
							</FormItem>
						</Col>
						<Col span="12">
							<FormItem prop="realName" label="真实姓名">
								<Input
									v-model.trim="formData.realName"
									readonly
									@on-focus="e => e.target.removeAttribute('readonly')"
									placeholder="请输入真实姓名"
								/>
							</FormItem>
						</Col>
						<Col span="12">
							<FormItem label="身份证号码" prop="idCardNo">
								<Input
									v-model.trim="formData.idCardNo"
									placeholder="请输入身份证号码"
								/>
							</FormItem>
						</Col>
						<Col span="12">
							<FormItem prop="sex" label="性别">
								<Select
									v-model="formData.sex"
									:label-in-value="true"
									placeholder="请选择性别"
								>
									<Option
										:value="list.id"
										v-for="list in sexList"
										:key="list.id"
										:label="list.name"
									></Option>
								</Select>
							</FormItem>
						</Col>
						<Col span="12">
							<FormItem prop="phone" label="手机号">
								<Input
									v-model.trim="formData.phone"
									placeholder="请输入手机号"
								/>
							</FormItem>
						</Col>
						<input type="text" class="input-hidden" />
						<input type="password" class="input-hidden" />
						<Col span="12">
							<FormItem prop="pass" label="密码">
								<Input
									type="password"
									v-model.trim="formData.pass"
									readonly
									@on-focus="e => e.target.removeAttribute('readonly')"
									placeholder="请输入密码"
								/>
							</FormItem>
						</Col>
						<Col span="12">
							<FormItem prop="confirmPass" label="确认密码">
								<Input
									type="password"
									v-model.trim="formData.confirmPass"
									placeholder="请输入确认密码"
								/>
							</FormItem>
						</Col>
						<Col span="24">
							<FormItem prop="roles" label="用户角色">
								<RadioGroup v-model="formData.roles">
									<Radio
										:label="it.id"
										v-for="(it, idx) in roleLists"
										:key="idx"
										>{{ it.name }}
									</Radio>
								</RadioGroup>
							</FormItem>
						</Col>
					</Row>
					<Row :gutter="16">
						<Col span="8">
							<FormItem
								prop="identityBack"
								:required="true"
								:show-message="false"
								label="身份证头像面"
							>
								<Upload
									action="/"
									:accept="filetype"
									:show-upload-list="false"
									:before-upload="file => getMD5(file, 'identityBack')"
								>
									<img
										style="width: 100%; height: 210px"
										:src="getPreviewImg(formData.identityBack)"
										class="preview"
										v-if="formData.identityBack"
									/>
									<div class="upload-btn-wrap" v-else>
										<Button
											type="primary"
											shape="circle"
											icon="md-add"
										></Button>
										<p>上传身份证头像面</p>
									</div>
								</Upload>
							</FormItem>
						</Col>
						<Col span="8">
							<FormItem
								prop="identityFront"
								:required="true"
								:show-message="false"
								label="身份证国徽面"
							>
								<Upload
									action="/"
									:accept="filetype"
									:show-upload-list="false"
									:before-upload="file => getMD5(file, 'identityFront')"
								>
									<img
										style="width: 100%; height: 210px"
										:src="getPreviewImg(formData.identityFront)"
										class="preview"
										v-if="formData.identityFront"
									/>
									<div class="upload-btn-wrap" v-else>
										<Button
											type="primary"
											shape="circle"
											icon="md-add"
										></Button>
										<p>上传身份证国徽面</p>
									</div>
								</Upload>
							</FormItem>
						</Col>
						<Col span="8">
							<FormItem
								prop="businessProxy"
								:required="true"
								:show-message="false"
								label="单位委托书"
							>
								<Upload
									action="/"
									:accept="filetype"
									:show-upload-list="false"
									:before-upload="file => getMD5(file, 'businessProxy')"
								>
									<img
										style="width: 100%; height: 210px"
										:src="getPreviewImg(formData.businessProxy)"
										class="preview"
										v-if="formData.businessProxy"
									/>
									<div class="upload-btn-wrap" v-else>
										<Button
											type="primary"
											shape="circle"
											icon="md-add"
										></Button>
										<p>上传单位委托书</p>
									</div>
								</Upload>
							</FormItem>
						</Col>
					</Row>
				</Form>
				<Row :gutter="60">
					<Col span="12" class="register-submit">
						<Button
							type="primary"
							:loading="loading"
							@click="handleSubmit"
							class="register-btn"
							>提交</Button
						>
					</Col>
					<Col span="12" class="register-cancel">
						<Button @click="handleCancel" class="register-btn">取消</Button>
					</Col>
				</Row>
			</div>
		</section>
	</div>
</template>
<script>
import { register, getDicList, getRoles, uploadFile } from "@/api/user/register"
import { dataToSha, formDataToMD5 } from "@/utils/util"
import "./style.less"

export default {
	components: {},
	data() {
		return {
			loading: false,
			formData: {
				businessLicence: []
			},
			ruleValidate: {
				orgType: {
					required: true,
					message: "组织类型不能为空",
					trigger: "change"
				},
				username: [
					{
						required: true,
						message: "邮箱地址不能为空",
						trigger: "blur"
					},
					{ max: 50, message: "邮箱地址长度不能超过50", trigger: "blur" },
					{
						type: "email",
						message: "邮箱地址格式不正确",
						trigger: "blur"
					}
				],
				pass: [
					{ required: true, message: "请输入密码" },
					{
						validator: "regexp",
						pattern: /^(?=.*[0-9].*)(?=.*[A-Z].*)(?=.*[a-z].*).{6,16}$/,
						message: "密码为6-16位，需要同时包含一个大、小写字母与数字",
						trigger: "blur"
					},
					{ validator: this.validatePass, trigger: "blur" }
				],
				confirmPass: [
					{ required: true, message: "请输入确认密码" },
					{
						validator: "regexp",
						pattern: /^(?=.*[0-9].*)(?=.*[A-Z].*)(?=.*[a-z].*).{6,16}$/,
						message: "密码为6-16位，需要同时包含一个大、小写字母与数字",
						trigger: "blur"
					},
					{ validator: this.validatePassRepeat, trigger: "blur" }
				],
				orgName: {
					required: true,
					message: "组织名称不能为空",
					trigger: "blur"
				},
				realName: [
					{
						required: true,
						message: "真实姓名不能为空",
						trigger: "blur"
					},
					{ max: 20, message: "真实姓名长度不能超过20", trigger: "blur" }
				],
				deptName: {
					required: true,
					message: "部门名称不能为空",
					trigger: "blur"
				},
				roles: {
					required: true,
					message: "用户角色不能为空",
					trigger: "change"
				},
				phone: [
					{
						required: true,
						message: "手机号不能为空",
						trigger: "blur"
					},
					{
						...this.rules.mobileNumber,
						message: "手机号码格式错误",
						trigger: "blur"
					}
				],
				sex: {
					required: true,
					message: "性别不能为空",
					trigger: "change"
				},
				idCardNo: [
					{
						required: true,
						message: "身份证号码不能为空",
						trigger: "blur"
					},
					{
						...this.rules.cardId,
						message: "身份证号码格式不正确",
						trigger: "blur"
					}
				]
			},
			dicList: [],
			sexList: [],
			roleLists: {},
			filetype: "image/jpg, image/jpeg, image/png, image/bmp"
		}
	},
	methods: {
		validatePass(rule, value, callback) {
			// 验证密码
			if (value === "") {
				callback(new Error("请输入密码"))
			} else {
				if (this.formData.confirmPass !== "") {
					this.$refs.formItem.validateField("confirmPass")
				}
				callback()
			}
		},
		validatePassRepeat(rule, value, callback) {
			// 验证确认密码
			if (value === "") {
				callback(new Error("请再次输入密码"))
			} else if (value !== this.formData.pass) {
				callback(new Error("两次输入密码不一致!"))
			} else {
				callback()
			}
		},
		getSelList() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await getDicList({ isDel: 0 })
				if (res) {
					this.dicList = res.data
						.find(el => el.id === "1")
						.leaf.filter(o => ["104", "105"].indexOf(o.id) === -1)
					this.sexList = res.data.find(el => el.id === "6").leaf
				}
				this.loading = false
			})
		},
		handleSubmit() {
			if (
				!this.formData.businessLicence ||
				!this.formData.businessLicence.length
			) {
				this.$Message.error("请上传营业执照！")
				return false
			}
			if (!this.formData.identityBack) {
				this.$Message.error("请上传身份证头像面！")
				return false
			}
			if (!this.formData.identityFront) {
				this.$Message.error("请上传身份证国徽面！")
				return false
			}
			if (!this.formData.businessProxy) {
				this.$Message.error("请上传单位委托书！")
				return false
			}
			this.$refs.formItem.validate(valid => {
				if (valid && this.formData.pass === this.formData.confirmPass) {
					const datas = {}
					Object.assign(datas, {
						...this.formData,
						pass: "",
						confirmPass: "",
						password: dataToSha(this.formData.confirmPass),
						roleIds: this.formData.roles,
						businessLicence: this.formData.businessLicence.join(",")
					})
					this.$asyncDo(async () => {
						const res = await register({
							...datas
						})
						if (res) {
							this.$Message.success("注册成功")
							this.$router.push({ name: "login" })
						}
					})
				}
			})
			return true
		},
		handleCancel() {
			this.$router.push({ name: "login" })
		},
		orgTypeChange(e) {
			this.$asyncDo(async () => {
				const ret = await getRoles({
					pageSize: 1000,
					pageNum: 0,
					type: e,
					isDel: 0
				})
				if (ret) {
					this.roleLists = ret.data.list
				}
			})
		},
		// md5
		getMD5(file, type) {
			if (!this.filetype.includes(file.type)) {
				this.$Message.error("只允许上传格式为jpg,png,bmp！")
				return false
			}
			if (file.size > 1024 * 1024 * 10) {
				this.$Message.error("文件大小不能超过10M！")
				return false
			}
			formDataToMD5(file, md5Str => {
				this.uploadFile(file, { md5: md5Str }, type)
			})
			return false
		},
		// 上传文件
		uploadFile(file, params, type) {
			this.$asyncDo(async () => {
				const fd = new FormData()
				fd.append("file", file)
				const res = await uploadFile(fd, params)
				if (res) {
					this.$Message.success("上传成功!")
					if (type === "businessLicence") {
						const businessLicence = this.formData.businessLicence
							? [...this.formData.businessLicence]
							: []
						businessLicence.push(res.data)
						this.$set(this.formData, type, businessLicence)
					} else {
						this.$set(this.formData, type, res.data)
					}
				}
			})
		},
		// 获取图片
		getPreviewImg(fileId) {
			return `${this.$baseUrl}/document/attachment/user-audit-file/${fileId}`
		},
		// 删除上传图片
		delBusinessLicenceImg(i) {
			let businessLicence = [...this.formData.businessLicence]
			businessLicence = businessLicence.filter((item, index) => index !== i)
			this.$set(this.formData, "businessLicence", businessLicence)
		}
	},
	created() {
		this.getSelList()
	}
}
</script>
